import { createRoot } from 'react-dom/client';
import { KeyboardNavigation } from 'Shared/keyboard_navigation';
import { DevicePickerModal } from '@ifixit/device-picker';
import { Initializer } from 'Shared/chakra-initialize';
import { initializeReactQuery } from 'Shared/react-query-initialize';
import { ViewLangIntlProvider } from 'Shared/use_intl';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { getSearchClient } from 'Guide/searchClient';
import { getGrowthbook } from 'Shared/features';

new KeyboardNavigation({
   keys: {
      'C-k': deviceNavigator,
      'M-k': deviceNavigator,
   },
});

export default async function deviceNavigator() {
   if (document.querySelector('[data-id="devicePickerComponent"]')) {
      return;
   }
   const node = document.createElement('div');
   document.body.append(node);
   const root = createRoot(node);
   const DevicePickerWithChakra = new Initializer(DevicePickerModal);
   root.render(
      initializeReactQuery(
         <ViewLangIntlProvider>
            <GrowthBookProvider growthbook={await getGrowthbook()}>
               <DevicePickerWithChakra
                  defaultIsOpen={true}
                  onSelect={device => (window.location.href = device.url)}
                  algoliaConfig={{
                     searchClient: getSearchClient(),
                     indexPrefix: App.algoliaConfig['indexPrefix'],
                  }}
                  oniFixit={App.which === 'ifixit'}
                  langid={App.lang}
                  preferredStore={App.userPreferredStoreCode}
                  treeNavigationProps={'showAreaButtons'}
                  analyticsKey={'Navigator'}
               />
            </GrowthBookProvider>
         </ViewLangIntlProvider>
      )
   );
}
