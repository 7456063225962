import { _js } from '@ifixit/localize';
import { Validation } from 'Shared/utils';
import { Recaptcha } from 'Shared/recaptcha';
import { setHideSubBox, onHideSubBoxChange } from 'Shared/ifixit_store';
import trackInPiwikAndGA from 'Shared/Analytics/CombinedGAPiwik';
import { querySelectorAllWithShadowed } from 'Shared/utils/shadow-wrapper';

class NewsLetter {
   constructor(el) {
      if (!el) {
         return;
      }

      this.el = el;
      this._getForm().addEventListener('submit', this._onSubmit.bind(this));

      onHideSubBoxChange(state => {
         if (state) {
            this._successStyle();
         } else {
            this._setDefault();
         }
      });
   }

   _getEl() {
      return this.el;
   }

   _getIconEl() {
      const iconClass = 'newsletterIcon';

      let iconEL = this.el.querySelector(`.${iconClass}`);

      if (iconEL) {
         return this.el;
      }

      iconEL = document.createElement('span');
      this.el.append(iconEL);
      iconEL.addClass(iconClass);

      return iconEL;
   }

   _getTextEl() {
      return this.el.querySelector('p');
   }

   _getEmailEl() {
      return this._getForm().querySelector('input');
   }

   _getForm() {
      return this.el.querySelector('form');
   }

   _getViewLinkEls() {
      return null;
   }

   _getEmailSuccessIcon() {
      return '';
   }

   _getEmailSuccessText() {
      return _js(
         'You are a dashing and intelligent person. We look forward to getting to know you.'
      );
   }

   _getEmailDefaultText() {
      return _js('Learn something new every week:');
   }

   _getEmailErrorIcon() {
      return '';
   }

   _getEmailErrorText() {
      return _js('Invalid Email');
   }

   _getGenericErrorText() {
      return _js('An Unknown Error Occurred. Please try again.');
   }

   _getRecaptchaKey() {
      return App.recaptchaToken;
   }

   _getRecaptchaPlaceholder() {
      const placeholderClass = 'newsletter-recaptcha';
      let recaptchaPlaceholder = this._getEmailEl().querySelector(`.${placeholderClass}`);

      if (recaptchaPlaceholder) {
         return recaptchaPlaceholder;
      }

      recaptchaPlaceholder = document.createElement('div');
      recaptchaPlaceholder.addClass(placeholderClass);
      this._getEmailEl().append(recaptchaPlaceholder);
      return recaptchaPlaceholder;
   }

   _signup() {
      return this._getRecaptchaPromise().then(recaptchaResponse =>
         new Request.AjaxIO('subscribe', {
            onSuccess: this._signupSuccess.bind(this),
         }).send(this._getEmailEl().value, recaptchaResponse)
      );
   }

   _signupWithLoading() {
      let signupPromise = this._signup();

      LoadingIndicator.withPromise(signupPromise, {
         pendingMessage: _js('Signing you up'),
         successMessage: _js('Signed up!'),
         failureMessage: _js('Something went wrong.'),
      });

      return signupPromise;
   }

   _getRecaptchaPromise() {
      return Recaptcha.executeInvisible(
         this._getRecaptchaPlaceholder(),
         this._getRecaptchaKey(),
         'newsletter'
      );
   }

   _signupSuccess(response) {
      if (typeof response == 'object') {
         response = response.result;
      }

      if (response == 'success') {
         this._setSuccess();
      } else {
         this._setError();
      }
   }

   _setDefault() {
      this.el.removeClass('error');
      this.el.removeClass('success');
      this._getIconEl().removeClass(this._getEmailSuccessIcon());
      this._getTextEl().set('text', this._getEmailDefaultText());
      this._getViewLinkEls()?.forEach(link => link.set('text', _js('Let me read it first!')));
   }

   _setSuccess() {
      setHideSubBox(true);
      let from = 'Footer';
      if (this.el.getAttribute('id') === 'newsletter-sub-box') {
         from = 'Newsletter page';
      }
      this._successStyle();

      this._successAnalytics(from);
   }

   _successStyle() {}

   _successAnalytics(from) {
      // Google Analytics and Facebook Tracking, respectively
      // Tracks sub box on /Newsletter and footer
      trackInPiwikAndGA({
         eventCategory: 'Newsletter',
         eventAction: `Newsletter - Form - ${from} - Submit`,
      });
      fbq('track', 'CompleteRegistration', { content_name: 'Newsletter' });
   }

   _setError() {
      this.el.addClass('error');
      this._getIconEl().addClass(this._getEmailErrorIcon());
      this._getTextEl().set('text', this._getEmailErrorText());
   }

   _validate() {
      const isValid = Validation.email(this._getEmailEl().value);
      if (!isValid) {
         this._setError();
      }
      return isValid;
   }

   _onSubmit(ev) {
      ev.stopPropagation();
      ev.preventDefault();
      this._setDefault();

      const isValid = this._validate();
      if (!isValid) {
         return;
      }

      this._signupWithLoading();
   }
}

class WPNewsletter extends NewsLetter {
   hiddenForm;
   slug;

   constructor(el, slug) {
      super(null);
      this.slug = slug;

      const hiddenForm = document.createElement('form');
      const hiddenInput = document.createElement('input');
      this.hiddenForm = hiddenForm;

      hiddenForm.style.display = 'none';
      hiddenForm.append(hiddenInput);
      document.body.append(hiddenForm);

      this.el = el;
      this._getForm().addEventListener('submit', ev => {
         ev.stopPropagation();
         ev.preventDefault();

         hiddenInput.value = this._getEmailEl().value;
         hiddenForm.addEventListener('submit', this._onSubmit.bind(this));
         hiddenForm.dispatchEvent(new Event('submit', { cancelable: true }));
      });

      onHideSubBoxChange(state => {
         if (state) {
            this._successStyle();
         } else {
            this._setDefault();
         }
      });
   }

   _getRecaptchaPlaceholder() {
      const placeholderClass = 'newsletter-recaptcha';
      let recaptchaPlaceholder = this.hiddenForm.querySelector(`.${placeholderClass}`);

      if (recaptchaPlaceholder) {
         return recaptchaPlaceholder;
      }

      recaptchaPlaceholder = document.createElement('div');
      recaptchaPlaceholder.addClass(placeholderClass);
      this.hiddenForm.append(recaptchaPlaceholder);
      return recaptchaPlaceholder;
   }

   _successAnalytics() {
      // Google Analytics and Facebook Tracking, respectively
      // Tracks sub box on Homepage
      trackInPiwikAndGA({
         eventCategory: 'Newsletter',
         eventAction: `Newsletter - Form - ${this.slug} - Submit`,
      });
      fbq('track', 'CompleteRegistration', { content_name: 'Newsletter' });
   }

   _getViewLinkEls() {
      return querySelectorAllWithShadowed('.subscribe-form .form-footer-text .link-text');
   }

   _successStyle() {
      this.el.removeClass('error');
      this.el.addClass('success');
      this._getIconEl().addClass(this._getEmailSuccessIcon());
      this._getTextEl().set('text', this._getEmailSuccessText());
      this._getForm().hide();
      this._getViewLinkEls()?.forEach(link => link.set('text', 'Let me read it!'));
   }
}

class FooterNewsletter extends NewsLetter {
   constructor(el) {
      super(el);
   }

   _setDefault() {}
}

export default NewsLetter;
export { WPNewsletter, FooterNewsletter };
